import { UserPermission } from '../entities/UserPermissions';

export const libraryPageAllowedUserPermissions = [
  UserPermission.librarySections,
  UserPermission.libraryFees,
  UserPermission.librarySnippets,
  UserPermission.libraryImages,
];

export const settingsPageAllowedUserPermissions = [
  UserPermission.settingsCurrency,
  UserPermission.settingsEmail,
  UserPermission.settingsFonts,
  UserPermission.settingsPreview,
  UserPermission.settingsTags,
  UserPermission.settingsTeam,
];

export const integrationsPageAllowedUserPermissions = [UserPermission.settingsIntegrations];

export const templatePageAllowedUserPermissions = [UserPermission.templatesEdit];

export const metricPageAllowedUserPermissions = [UserPermission.metricsView];

export const usersPageAllowedUserPermissions = [UserPermission.usersView];

export const clientsPageAllowedUserPermissions = [UserPermission.clientsView];

type isPageAllowedParams = {
  userPermissions: UserPermission[];
  allowedPermissions: UserPermission[];
};

export const isPageAllowed = ({ userPermissions, allowedPermissions }: isPageAllowedParams): boolean => {
  if (allowedPermissions.length === 0) {
    return true;
  }

  return userPermissions.some((permission) => allowedPermissions.includes(permission));
};
