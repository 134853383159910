export enum UserPermission {
  isAdmin,

  templatesEdit,

  librarySections,
  libraryFees,
  librarySnippets,
  libraryImages,

  settingsIntegrations,

  settingsTeam,
  settingsEmail,
  settingsFonts,
  settingsTags,
  settingsCurrency,
  settingsPreview,

  metricsView,
  usersView,
  clientsView,
  purchaseSeat,
}
